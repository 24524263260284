<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            :color="filterApplied ? 'orange' : 'msaBlue'"
            dark
            v-blur
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        Filter
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row align="center">
          <v-col class="text-h6 pl-6 grow"> Filter </v-col>
          <v-col class="shrink pr-6">
            <v-btn
              icon
              @click="
                dialog = false;
                init();
              "
            >
              <v-icon color="white"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="mt-4">
        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Company </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              :value="
                $route.name == 'WebLoginAudits'
                  ? thisFilters.companyIds
                  : thisFilters.companyId
              "
              :multiple="$route.name == 'WebLoginAudits'"
              :label="
                $route.name == 'WebLoginAudits'
                  ? 'Select company(s)'
                  : 'Select a company'
              "
              hide-details
              :items="filteredCompanies"
              item-text="Name"
              item-value="ID"
              :clearable="$route.name == 'WebLoginAudits'"
              :deletable-chips="$route.name == 'WebLoginAudits'"
              @change="
                $route.name == 'WebLoginAudits'
                  ? (thisFilters.companyIds = $event)
                  : (thisFilters.companyId = $event)
              "
            />
          </v-col>
        </v-row>

        <v-row v-if="$route.name == 'WebLoginAudits'" dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Account Types </v-col>
          <v-col class="pb-2">
            <CustomizedAutoComplete
              label="Select Account Types"
              hide-details
              :items="subscriptionTypes"
              item-text="label"
              item-value="id"
              :clearable="true"
              :deletable-chips="true"
              v-model="thisFilters.companySubscriptionTypes"
              @change="thisFilters.companySubscriptionTypes = $event"
              :multiple="true"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold">
            Company Status
          </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.companyStatus"
              mandatory
              color="msaBlue"
              :style="{ width: '100%' }"
              @change="onCompanyStatusChange"
              dense
            >
              <v-btn :value="2" :style="{ width: '33%' }"> all </v-btn>
              <v-btn :value="1" :style="{ width: '33%' }"> active </v-btn>
              <v-btn :value="0" :style="{ width: '33%' }"> inactive </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> User Status </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.userStatus"
              mandatory
              color="msaBlue"
              :style="{ width: '100%' }"
              dense
            >
              <v-btn
                :value="2"
                :style="{ width: '33%' }"
                :disabled="thisFilters.companyStatus == 0"
              >
                all
              </v-btn>
              <v-btn
                :value="1"
                :style="{ width: '33%' }"
                :disabled="thisFilters.companyStatus == 0"
              >
                active
              </v-btn>
              <v-btn
                :value="0"
                :style="{ width: '33%' }"
                :disabled="thisFilters.companyStatus == 0"
              >
                inactive
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Web Admin </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.isWebAdmin"
              mandatory
              color="msaBlue"
              :style="{ width: '100%' }"
              dense
            >
              <v-btn :value="2" :style="{ width: '33%' }"> all </v-btn>
              <v-btn :value="1" :style="{ width: '33%' }"> yes </v-btn>
              <v-btn :value="0" :style="{ width: '33%' }"> no </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Supervisor </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.isSupervisor"
              mandatory
              color="msaBlue"
              :style="{ width: '100%' }"
              dense
            >
              <v-btn :value="2" :style="{ width: '33%' }"> all </v-btn>
              <v-btn :value="1" :style="{ width: '33%' }"> yes </v-btn>
              <v-btn :value="0" :style="{ width: '33%' }"> no </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Manager </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.isManager"
              mandatory
              color="msaBlue"
              :style="{ width: '100%' }"
              dense
            >
              <v-btn :value="2" :style="{ width: '33%' }"> all </v-btn>
              <v-btn :value="1" :style="{ width: '33%' }"> yes </v-btn>
              <v-btn :value="0" :style="{ width: '33%' }"> no </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Safety </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.isSafetyRep"
              mandatory
              color="msaBlue"
              :style="{ width: '100%' }"
              dense
            >
              <v-btn :value="2" :style="{ width: '33%' }"> all </v-btn>
              <v-btn :value="1" :style="{ width: '33%' }"> yes </v-btn>
              <v-btn :value="0" :style="{ width: '33%' }"> no </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" class="pb-0 font-weight-bold"> Group Admin </v-col>
          <v-col class="pb-2">
            <v-btn-toggle
              v-model="thisFilters.isGroupAdmin"
              mandatory
              color="msaBlue"
              :style="{ width: '100%' }"
              dense
            >
              <v-btn :value="2" :style="{ width: '33%' }"> all </v-btn>
              <v-btn :value="1" :style="{ width: '33%' }"> yes </v-btn>
              <v-btn :value="0" :style="{ width: '33%' }"> no </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row dense v-if="$route.name == 'WebLoginAudits'">
          <v-col cols="12" class="pb-0 font-weight-bold"> Range </v-col>
          <v-col class="pb-2" cols="12" md="6">
            <v-menu
              v-model="fromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.from"
                  label="From"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.from"
                no-title
                :max="fromMax"
                @input="fromMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="pb-2" cols="12" md="6">
            <v-menu
              v-model="toMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="thisFilters.to"
                  label="To"
                  readonly
                  outlined
                  dense
                  clearable
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="thisFilters.to"
                no-title
                :min="toMin"
                @input="toMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row dense v-if="$route.name == 'DocumentDownloadAudits'">
          <v-col cols="12" class="pb-0 font-weight-bold"> Document Name </v-col>
          <v-col class="pb-2">
            <v-text-field
              v-model="thisFilters.documentName"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense v-if="$route.name == 'DocumentDownloadAudits'">
          <v-col cols="12" class="pb-0 font-weight-bold">
            Document Group Name
          </v-col>
          <v-col class="pb-2">
            <v-text-field
              v-model="thisFilters.documentGroupName"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="clear()"> clear </v-btn>
        <v-btn class="msaBlue white--text" @click="apply()"> apply </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AuditsReportFilters',
  props: {
    filters: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      thisFilters: JSON.parse(
        JSON.stringify(this.$constants.AUDITS_REPORT_FILTERS),
      ),
      companies: [],
      companiesLoaded: false,
      subscriptionTypes: [],
      subscriptionTypesLoaded: false,
      fromMenu: false,
      toMenu: false,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.init();
      }
    },
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.AUDITS_REPORT_FILTERS)
      );
    },
    toMin() {
      return this.thisFilters.from == '' ? undefined : this.thisFilters.from;
    },
    fromMax() {
      return this.thisFilters.to == '' ? undefined : this.thisFilters.to;
    },
    filteredCompanies() {
      if (this.thisFilters.companyStatus == 0) {
        return this.companies.filter((company) => company.Status == 'Inactive');
      } else if (this.thisFilters.companyStatus == 1) {
        return this.companies.filter((company) => company.Status == 'Active');
      } else {
        return this.companies;
      }
    },
  },
  methods: {
    clear() {
      this.thisFilters = JSON.parse(
        JSON.stringify(this.$constants.AUDITS_REPORT_FILTERS),
      );
      this.apply();
    },
    apply() {
      this.$emit('applyFilters', this.thisFilters);
      this.dialog = false;
    },
    init() {
      this.thisFilters = JSON.parse(JSON.stringify(this.filters));
      if (!this.companiesLoaded) {
        this.getCompanies();
      }

      if (
        !this.subscriptionTypesLoaded &&
        this.$route.name == 'WebLoginAudits'
      ) {
        this.getSubscriptionTypes();
      }
    },
    getCompanies() {
      const params = {
        loaderText: 'Loading...',
      };
      const url = 'get-companies-for-filter?format=json';
      this.$axios.post(url, params).then((response) => {
        this.companies = response.data;
        this.companiesLoaded = true;
      });
    },
    getSubscriptionTypes() {
      const params = {
        loaderText: 'Loading...',
      };

      const url = 'get-subscription-types-for-filter';
      this.$axios.post(url, params).then((response) => {
        this.subscriptionTypes = response.data;
        this.subscriptionTypesLoaded = true;
      });
    },
    onCompanyStatusChange(e) {
      this.thisFilters.companyIds = [];
      if (e == 0) {
        this.thisFilters.userStatus = 0;
      }
    },
  },
};
</script>

<style scoped></style>
